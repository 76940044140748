<template>
  <div>
    <v-dialog width="450" v-model="show_dialog">
      <v-card>
        <v-card-title
          class="primary_2 d-flex justify-space-between font-weight-bold white--text"
        >
          Change Loan Permission
          <div>
            <v-btn text small fab @click="closeDialog()">
              <v-icon large color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-4 pb-0">
          <v-form ref="change_loan_permission">
            Select Loan Type
            <!-- <v-chip-group
              mandatory
              v-model="loan_type"
              column
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip
                v-for="(item, index) in loan_type_list"
                :key="index"
                outlined
              >
                {{ item.text }}
              </v-chip>
            </v-chip-group> -->
            <v-select
              :items="loan_type_list"
              v-model="loan_type"
              outlined
              dense
              item-text="text"
              item-value="value"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pt-0 pr-6 pb-4">
          <v-btn
            color="secondary_2"
            :loading="btn_loader"
            :disabled="btn_loader"
            class="font-weight-bold white--text"
            @click="changeLoanPermission()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      loan_type: [],
      loan_type_list: [
        {
          text: "Personal Loan & Mutual Fund Loan",
          value: ["Personal Loan", "Mutual Fund Loan"],
          color: "#084874",
        },
        {
          text: "Business Loan & Mutual Fund Loan",
          value: ["Business Loan", "Mutual Fund Loan"],
          color: "#4a52ed",
        },
        {
          text: "Mutual Fund Loan",
          value: ["Mutual Fund Loan"],
          color: "#ff462b",
        },
      ],
    };
  },
  methods: {
    openLoanPermissionDialog(item) {
      this.show_dialog = true;
      this.loan_type = item.loan_type;
      console.log("openLoanPermissionDialog", item.loan_type);
    },
    closeDialog() {
      this.$refs.change_loan_permission.reset();
      this.show_dialog = false;
      this.btn_loader = false;
    },
    changeLoanPermission() {
      if (this.$refs.change_loan_permission.validate()) {
        const self = this;
        self.btn_loader = true;
        let customer_id = self.decrypt(self.$route.params.id);

        let body = {
          permissions_on_loan: self.loan_type,
        };

        const successHandler = (response) => {
          self.show_dialog = false;
          this.$emit("refresh");
          console.log("openLoanPermissionDialog Success", response);
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.PATCH_request(
          self,
          self.$urls.SET_LOAN_PERMISSIONS,
          customer_id,
          null,
          body,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
<style scoped>
.selcted-chip {
  box-shadow: 0 0 10px #2a2a2ab5;
}
</style>
